
// /**
//  *
//  */

+function($){
  page.registerVendor('Constellation');

  page.initConstellation = function() {
    var distance = 170;

    if( $( window ).width() < 700) {
      distance = 25;
    }

    $('.constellation').each(function(){
      var canvas = $(this),
          color  = canvas.dataAttr( 'color', 'rgba(255, 255, 255, .1)' ),
          length = canvas.dataAttr( 'length', 300 ),
          radius = canvas.dataAttr( 'radius', 200 ),
          starW  = canvas.dataAttr( 'starWidth', 1.5 ),
          lineW  = canvas.dataAttr( 'lineWidth', 0.3 );

      if ( color == 'dark' ) {
        color = 'rgba(0, 0, 0, .6)';
      }

      canvas.constellation({
        distance: distance,
        length: length,
        radius: radius,
        star: {
          color: color,
          width: starW
        },
        line: {
          color: color,
          width: lineW
        }
      });
    });

  }

}(jQuery);


// // https://codepen.io/acauamontiel/pen/mJdnw

// /*!
//  * Mantis.js / jQuery / Zepto.js plugin for Constellation
//  * @version 1.2.2
//  * @author Acauã Montiel <contato@acauamontiel.com.br>
//  * @license http://acaua.mit-license.org/
//  */
(function ($, window) {
  /**
   * Makes a nice constellation on canvas
   * @constructor Constellation
   */
  function Constellation (canvas, options) {
    var $canvas = $(canvas),
      context = canvas.getContext('2d'),
      defaults = {
        star: {
          color: 'rgba(255, 255, 255, .5)',
          width: 1
        },
        line: {
          color: 'rgba(255, 255, 255, .5)',
          width: 0.2
        },
        position: {
          x: 0, // This value will be overwritten at startup
          y: 0 // This value will be overwritten at startup
        },
        width: window.innerWidth,
        height: window.innerHeight,
        velocity: 0.5,
        length: 150,
        distance: 150,
        radius: 150,
        stars: [],
        lastFrameTime: Date.now(),
        lastFrameTimeC: Date.now(),
        frameInterval: 1000 / 10, // 15 FPS
        frameIntervalC: 1000 / 5 // 15 FPS
      },
      config = $.extend(true, {}, defaults, options);

    function Star () {
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;

      this.vx = (config.velocity - (Math.random() * 0.8));
      this.vy = (config.velocity - (Math.random() * 0.7));

      this.radius = Math.random() * config.star.width;
    }

    Star.prototype = {
      create: function(){
        context.beginPath();
        //this.drawAsterix(this.x, this.y, this.radius, context);
        context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        context.fill();
      },

      // animate: function() {
      //   var now = Date.now();
      //   var elapsed = now - config.lastFrameTime;

      //   if (elapsed > config.frameInterval) {
      //       lastFrameTime = now - (elapsed % config.frameInterval);
      //       context.clearRect(0, 0, canvas.width, canvas.height);

      //       config.stars.forEach(function(star) {
      //           star.x += star.vx;
      //           star.y += star.vy;

      //           if (star.y < 0 || star.y > canvas.height) star.vy = -star.vy;
      //           if (star.x < 0 || star.x > canvas.width) star.vx = -star.vx;

      //           star.create();
      //       });
      //   }
      //   requestAnimationFrame(this.animate);
      // },

      animate: function(){
        var i;
        for (i = 0; i < config.length; i++) {

          var star = config.stars[i];

          if (star.y < 0 || star.y > canvas.height) {
            star.vx = star.vx;
            star.vy = - star.vy;
          } else if (star.x < 0 || star.x > canvas.width) {
            star.vx = - star.vx;
            star.vy = star.vy;
          }

          star.x += star.vx;
          star.y += star.vy;
        }
      },

      line: function(){
        var length = config.length,
          iStar,
          jStar,
          i,
          j;

        for (i = 0; i < length; i++) {
          for (j = 0; j < length; j++) {
            iStar = config.stars[i];
            jStar = config.stars[j];

            if (
              (iStar.x - jStar.x) < config.distance &&
              (iStar.y - jStar.y) < config.distance &&
              (iStar.x - jStar.x) > - config.distance &&
              (iStar.y - jStar.y) > - config.distance
            ) {
              if (
                (iStar.x - config.position.x) < config.radius &&
                (iStar.y - config.position.y) < config.radius &&
                (iStar.x - config.position.x) > - config.radius &&
                (iStar.y - config.position.y) > - config.radius
              ) {
                context.beginPath();
                context.moveTo(iStar.x, iStar.y);
                context.lineTo(jStar.x, jStar.y);
                context.stroke();
                context.closePath();
              }
            }
          }
        }
      }
    };

    this.createStars = function () {
      var length = config.length,
        star,
        i;

      context.clearRect(0, 0, canvas.width, canvas.height);

      for (i = 0; i < length; i++) {
        config.stars.push(new Star());
        star = config.stars[i];

        star.create();
      }

      star.line();
      star.animate();
    };

    this.setCanvas = function () {
      canvas.width = config.width;
      canvas.height = config.height;
    };

    this.setContext = function () {
      context.fillStyle = config.star.color;
      context.strokeStyle = config.line.color;
      context.lineWidth = config.line.width;
    };

    this.setInitialPosition = function () {
      if (!options || !options.hasOwnProperty('position')) {
        config.position = {
          x: canvas.width * 0.5,
          y: canvas.height * 0.5
        };
      }
    };

    this.loop = function (callback) {
      var now = Date.now();
      var elapsed = now - config.lastFrameTime;
      var elapsedC = now - config.lastFrameTimeC;

      if (elapsed > config.frameInterval) {
        config.lastFrameTime = now - (elapsed % config.frameInterval);
        callback();
      }

      // if (elapsedC > config.frameIntervalC) {
      //   config.lastFrameTimeC = now - (elapsedC % config.frameIntervalC);
      //   config.line.color = context.strokeStyle = this.generateSimilarRGBA(config.line.color);
      // }

      window.requestAnimationFrame(function () {
          //stats.begin(); // Only for Stats
          this.loop(callback);
          //stats.end(); // Only for Stats
      }.bind(this));
    };

    this.bind = function () {
      $canvas.on('mousemove', function(e){
        config.position.x = e.pageX - $canvas.offset().left;
        config.position.y = e.pageY - $canvas.offset().top;
      });
    };
    
    this.init = function () {
      this.setCanvas();
      this.setContext();
      this.setInitialPosition();
      this.loop(this.createStars);
      this.bind();

      // Set a timer to call a specific function every 5 seconds
      //setInterval(() => {
        //this.createStars();
        // Call any function you want to execute every 5 seconds here
        // For demonstration, let's just recreate the stars which will
        // effectively reset the animation and apply any potential changes
        // in configuration such as position, etc.
        //config.line.color = context.strokeStyle = this.generateSimilarRGBA(context.strokeStyle);
      //}, 2000); // 5000 milliseconds = 5 seconds
    };

    this.generateSimilarRGBA = function(currentColorRGBA) {
      // Parse the current RGBA color
      const rgba = currentColorRGBA.match(/\d+/g).map(Number); // Extracts RGB values from an RGBA string
    
      // Adjust the color components slightly to create a new, similar color
      function adjustColorComponent(component, _variance) {
        const variance = _variance; // Determines how much the color can change
        const delta = Math.floor(Math.random() * variance) - (variance / 2);
        let newComponent = component + delta;
        // Ensure that RGB values are within the valid range (0-255)
        return Math.min(255, Math.max(0, newComponent));
      }
    
      const newColor = {
        r: adjustColorComponent(rgba[0], 5),
        g: adjustColorComponent(rgba[1], 50),
        b: adjustColorComponent(rgba[2], 40),
      };

      
    
      // Return the new color in RGBA format, preserving the original alpha value
      return `rgba(${newColor.r}, ${newColor.g}, ${newColor.b}, 1)`; // Assuming the alpha is provided as 0-255
    };
  }

  $.fn.constellation = function (options) {
    return this.each(function () {
      var c = new Constellation(this, options);
      c.init();
    });
  };
})($, window);


